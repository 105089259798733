
import banner1 from "../../../assets/image/banners/d1.jpg"
import banner2 from "../../../assets/image/banners/d2.jpg"
import banner3 from "../../../assets/image/banners/d3.jpg"
import banner4 from "../../../assets/image/banners/d4.jpg"

export const TestimonialData = [
    {
    image:banner1,
    text:'"John helped us deliver a successful update to a responsive design. His willingness to see the job through is a great."',
    user:"Ann Franks"
},
    {
    image:banner2,
    text:'"John helped us deliver a successful update to a responsive design. His willingness to see the job through is a great."',
    user:"Ann Franks"
},
    {
    image:banner3,
    text:'"John helped us deliver a successful update to a responsive design. His willingness to see the job through is a great."',
    user:"Ann Franks"
},
    {
    image:banner4,
    text:'"John helped us deliver a successful update to a responsive design. His willingness to see the job through is a great."',
    user:"Ann Franks"
},
]