import image1 from '../assets/image/collection/2021/c_2021_1.jpg';
import image2 from '../assets/image/collection/2021/c_2021_2.jpg';
import image3 from '../assets/image/collection/2021/c_2021_3.jpg';
import image4 from '../assets/image/collection/2021/c_2021_4.jpg';
import image5 from '../assets/image/collection/2021/c_2021_5.jpg';
import image6 from '../assets/image/collection/2021/c_2021_6.jpg';
import image7 from '../assets/image/collection/2021/c_2021_7.jpg';
import image8 from '../assets/image/collection/2021/c_2021_8.jpg';
import image9 from '../assets/image/collection/2021/c_2021_9.jpg';
import image10  from '../assets/image/collection/2021/c_2021_10.jpg';
import image11  from '../assets/image/collection/2021/c_2021_11.jpg';
import image12  from '../assets/image/collection/2021/c_2021_12.jpg';
import image13  from '../assets/image/collection/2021/c_2021_13.jpg';
import image14  from '../assets/image/collection/2021/c_2021_14.jpg';
import image15  from '../assets/image/collection/2021/c_2021_15.jpg';
import image16  from '../assets/image/collection/2021/c_2021_16.jpg';
import image17  from '../assets/image/collection/2021/c_2021_17.jpg';
import image18  from '../assets/image/collection/2021/c_2021_18.jpg';
import image19  from '../assets/image/collection/2021/c_2021_19.jpg';
import image20  from '../assets/image/collection/2021/c_2021_20.jpg';
import image21  from '../assets/image/collection/2021/c_2021_21.jpg';
import image22  from '../assets/image/collection/2021/c_2021_22.jpg';
import image23  from '../assets/image/collection/2021/c_2021_23.jpg';
import image24  from '../assets/image/collection/2021/c_2021_24.jpg';
import image25  from '../assets/image/collection/2021/c_2021_25.jpg';
import image26  from '../assets/image/collection/2021/c_2021_26.jpg';
import image27  from '../assets/image/collection/2021/c_2021_27.jpg';
import image28  from '../assets/image/collection/2021/c_2021_28.jpg';
import image29  from '../assets/image/collection/2021/c_2021_29.jpg';
import image30  from '../assets/image/collection/2021/c_2021_30.jpg';
import image31  from '../assets/image/collection/2021/c_2021_31.jpg';
import image32  from '../assets/image/collection/2021/c_2021_32.jpg';
import image33  from '../assets/image/collection/2021/c_2021_33.jpg';
import image34  from '../assets/image/collection/2021/c_2021_34.jpg';
// import image35  from '../assets/image/collection/2021/c_2021_35.jpg';
import image36  from '../assets/image/collection/2021/c_2021_36.jpg';
import image37  from '../assets/image/collection/2021/c_2021_37.jpg';
import image38  from '../assets/image/collection/2021/c_2021_38.jpg';
import image39  from '../assets/image/collection/2021/c_2021_39.jpg';
import image40  from '../assets/image/collection/2021/c_2021_40.jpg';
// import image41  from '../assets/image/collection/2021/c_2021_41.jpg';
import image42  from '../assets/image/collection/2021/c_2021_42.jpg';
// import image43  from '../assets/image/collection/2021/c_2021_43.jpg';
import image44  from '../assets/image/collection/2021/c_2021_44.jpg';
import image45  from '../assets/image/collection/2021/c_2021_45.jpg';
import image46  from '../assets/image/collection/2021/c_2021_46.jpg';
import image47  from '../assets/image/collection/2021/c_2021_47.jpg';
import image48  from '../assets/image/collection/2021/c_2021_48.jpg';

const GalleryCakes = {}

GalleryCakes.masonryDetails2 =[

{
  image:image3,
  titleSmall:"PiHome",
  title:"Banh",
  id:"3",
  tagi: "photography"
},
{
  image:image4,
  titleSmall:"PiHome",
  title:"Banh",
  id:"4",
  tagi: "animal"
},
{
  image:image5,
  titleSmall:"PiHome",
  title:"Banh",
  id:"5",
  tagi: "animal"
},
{
  image:image6,
  titleSmall:"PiHome",
  title:"Banh",
  id:"6",
  tagi: "animal"
},
{
  image:image7,
  titleSmall:"PiHome",
  title:"Banh",
  id:"7",
  tagi: "animal"
},
{
  image:image8,
  titleSmall:"PiHome",
  title:"Banh",
  id:"8",
  tagi: "animal"
},
{
  image:image10,
  titleSmall:"PiHome",
  title:"Banh",
  id:"10",
  tagi: "photography"
},
{
  image:image11,
  titleSmall:"PiHome",
  title:"Banh",
  id:"11",
  tagi: "photography"
},
{
  image:image12,
  titleSmall:"PiHome",
  title:"Banh",
  id:"12",
  tagi: "photography"
},
{
  image:image13,
  titleSmall:"PiHome",
  title:"Banh",
  id:"13",
  tagi: "photography"
},
{
  image:image14,
  titleSmall:"PiHome",
  title:"Banh",
  id:"14",
  tagi: "photography"
},
{
  image:image15,
  titleSmall:"PiHome",
  title:"Banh",
  id:"15",
  tagi: "photography"
},
{
  image:image16,
  titleSmall:"PiHome",
  title:"Banh",
  id:"16",
  tagi: "photography"
},
{
  image:image17,
  titleSmall:"PiHome",
  title:"Banh",
  id:"17",
  tagi: "deo"
},

{
  image:image19,
  titleSmall:"PiHome",
  title:"Banh",
  id:"19",
  tagi: "photography"
},
{
  image:image20,
  titleSmall:"PiHome",
  title:"Banh",
  id:"20",
  tagi: "deo"
},
{
  image:image21,
  titleSmall:"PiHome",
  title:"Banh",
  id:"21",
  tagi: "set"
},
{
  image:image32,
  titleSmall:"PiHome",
  title:"Banh",
  id:"32",
  tagi: "photography"
},
{
  image:image33,
  titleSmall:"PiHome",
  title:"Banh",
  id:"33",
  tagi: "modern"
},
{
  image:image34,
  titleSmall:"PiHome",
  title:"Banh",
  id:"34",
  tagi: "photography"
},
{
  image:image31,
  titleSmall:"PiHome",
  title:"Banh",
  id:"31",
  tagi: "animal"
},
// {
//   image:image43,
//   titleSmall:"PiHome",
//   title:"Banh",
//   id:"43",
//   tagi: "animal"
// },
// {
//   image:image35,
//   titleSmall:"PiHome",
//   title:"Banh",
//   id:"35",
//   tagi: "animal"
// },
// {
//   image:image41,
//   titleSmall:"PiHome",
//   title:"Banh",
//   id:"41",
//   tagi: "modern"
// },
{
  image:image36,
  titleSmall:"PiHome",
  title:"Banh",
  id:"animal",
  tagi: "set"
},
{
  image:image30,
  titleSmall:"PiHome",
  title:"Banh",
  id:"30",
  tagi: "set"
},
{
  image:image22,
  titleSmall:"PiHome",
  title:"Banh",
  id:"22",
  tagi: "set"
},
{
  image:image23,
  titleSmall:"PiHome",
  title:"Banh",
  id:"23",
  tagi: "set"
},
{
  image:image24,
  titleSmall:"PiHome",
  title:"Banh",
  id:"24",
  tagi: "set"
},
{
  image:image25,
  titleSmall:"PiHome",
  title:"Banh",
  id:"25",
  tagi: "set"
},
{
  image:image26,
  titleSmall:"PiHome",
  title:"Banh",
  id:"26",
  tagi: "set"
},
{
  image:image27,
  titleSmall:"PiHome",
  title:"Banh",
  id:"27",
  tagi: "set"
},
{
  image:image28,
  titleSmall:"PiHome",
  title:"Banh",
  id:"28",
  tagi: "set"
},
{
  image:image29,
  titleSmall:"PiHome",
  title:"Banh",
  id:"29",
  tagi: "set"
},
{
  image:image37,
  titleSmall:"PiHome",
  title:"Banh",
  id:"37",
  tagi: "set"
},
{
  image:image38,
  titleSmall:"PiHome",
  title:"Banh",
  id:"38",
  tagi: "set"
},
{
  image:image39,
  titleSmall:"PiHome",
  title:"Banh",
  id:"39",
  tagi: "set"
},
{
  image:image40,
  titleSmall:"PiHome",
  title:"Banh",
  id:"40",
  tagi: "set"
},
{
  image:image42,
  titleSmall:"PiHome",
  title:"Banh",
  id:"42",
  tagi: "set"
},
{
  image:image44,
  titleSmall:"PiHome",
  title:"Banh",
  id:"44",
  tagi: "set"
},
{
  image:image45,
  titleSmall:"PiHome",
  title:"Banh",
  id:"45",
  tagi: "set"
},
{
  image:image46,
  titleSmall:"PiHome",
  title:"Banh",
  id:"46",
  tagi: "set"
},
{
  image:image47,
  titleSmall:"PiHome",
  title:"Banh",
  id:"47",
  tagi: "set"
},
{
  image:image48,
  titleSmall:"PiHome",
  title:"Banh",
  id:"48",
  tagi: "set"
},
{
  image:image1,
  titleSmall:"PiHome",
  title:"HẰNG NGA",
  id:"1",
  tagi: "set"
},
{
  image:image2,
  titleSmall:"PiHome",
  title:"HẰNG NGA",
  id:"2",
  tagi: "set"
},
{
  image:image9,
  titleSmall:"PiHome",
  title:"Banh",
  id:"9",
  tagi: "set"
},
{
  image:image18,
  titleSmall:"PiHome",
  title:"Banh",
  id:"18",
  tagi: "set"
},
]
export default GalleryCakes;